import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

import TitreSlide from "./TitreSlide";

interface Member {
  id: number;
  nom: string;
  role: string;
  image: string;
  texte: string;
  tel: string;
  mail: string;
}

function VSlide4() {
  const [show, setShow] = useState(false);
  const [selectedMember, setSelectedMember] = useState<number | null>(null);

  const equipeData: Member[] = [
    {
      id: 1,
      nom: "01 - Vincent",
      role: "Développeur",
      image: "./images/Team/Vincent-rigolo.png",
      texte:
        "'Si le taff est bien fait, tu ne vois pas le boulot, ce n'est pas en deux semaines que tu feras foule OH !'",
      tel: "(+33) 06 19 28 83 86",
      mail: "vincent.marconnet@pulsar-inc.fr",
    },
    {
      id: 2,
      nom: "02 - Tim",
      role: "Manager / Commercial",
      image: "./images/Team/TimSéminaire.png",
      texte: "'Pulsar est là pour marquer les esprits!'",
      tel: "(+33) 06 21 47 82 06",
      mail: "tim.chatard@pulsar-inc.fr",
    },
    {
      id: 3,
      nom: "03 - Antoine",
      role: "Responsable communication",
      image: "./images/Team/AntoineSéminaire.png",
      texte:
        "'Je mitraille à l'appareil photo et trouve les bons mots quand il le faut.'",
      tel: "(+33) 06 41 75 98 68",
      mail: "antoine.legros@pulsar-inc.fr",
    },
    // {
    //   id: 4,
    //   nom: "04 - RedWood",
    //   role: "Beatmaker",
    //   image: "./images/Team/RedwoodSéminaire.png",
    //   texte: "'French 27 yo beatmaker based in Lyon, France'",
    //   tel: "",
    //   mail: "",
    // },
  ];

  const handleMemberClick = (memberId: number) => {
    if (selectedMember === memberId) {
      setSelectedMember(null);
    } else {
      setSelectedMember(memberId);
    }
  };

  return (
    <motion.section
      layout
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.2, delay: 0 }}
      className="NotreEquipe"
      id="NotreEquipe"
    >
      <TitreSlide GrosTitre="Notre équipe" PetitTitre="" />
      <ul className="tracklist">
        {equipeData.map((member, index) => (
          <motion.li
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1, delay: index * 0.1 }}
            className={`track ${
              selectedMember === member.id ? "selected" : ""
            }`}
          >
            <div
              className="Vslide4-container_track"
              onClick={() => handleMemberClick(member.id)}
            >
              <div className="track-title">
                {" "}
                <strong>{member.nom}</strong>
              </div>
              <div className="track-separator"></div>
              <div className="track-artist">{member.role}</div>
            </div>
            <AnimatePresence>
              {selectedMember === member.id && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "20vh" }}
                  exit={{ opacity: 0, height: 0 }}
                  transition={{ duration: 0.3 }}
                  className="member-details"
                >
                  <img src={member.image} alt={member.nom} />
                  <div className="member-details-infos">
                    <p>{member.texte}</p>
                    <p>{member.tel}</p>
                    <p>{member.mail}</p>
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </motion.li>
        ))}
      </ul>
    </motion.section>
  );
}

export default VSlide4;
