import React, { useState, useEffect } from "react";
import { FaArrowAltCircleUp } from "react-icons/fa";
import LocomotiveScroll from "locomotive-scroll";

interface ScrollToTopButtonProps {
  locomotiveScroll?: LocomotiveScroll | null;
}

const ScrollToTopButton: React.FC<ScrollToTopButtonProps> = ({
  locomotiveScroll,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const scrollThreshold = 300;

  useEffect(() => {
    const handleLocomotiveScroll = (args: { scroll: { y: number } }) => {
      const currentPosition = args.scroll.y;
      console.log("Locomotive Scroll Position STTB:", currentPosition);
      if (currentPosition > scrollThreshold) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    if (!locomotiveScroll) {
      const handleScroll = () => {
        const currentPosition = window.pageYOffset;
        console.log("Page Scroll Position:", currentPosition);
        if (currentPosition > scrollThreshold) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      };

      window.addEventListener("scroll", handleScroll);
      return () => window.removeEventListener("scroll", handleScroll);
    } else {
      locomotiveScroll.on("scroll", handleLocomotiveScroll);

      return () => {
        locomotiveScroll.destroy();
      };
    }
  }, [locomotiveScroll]);

  // Fonction pour défiler vers le haut
  const scrollToTop = () => {
    if (locomotiveScroll) {
      locomotiveScroll.scrollTo("top", {
        duration: 500,
        easing: [0.25, 0.0, 0.35, 1.0],
      });
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  return (
    <div
      className={`scroll-to-top-button ${isVisible ? "visible" : ""}`}
      onClick={scrollToTop}
    >
      <FaArrowAltCircleUp />
    </div>
  );
};

export default ScrollToTopButton;
