import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { shoppingCartActions } from "../Store/shopping-cart-slice";

// Définition des propriétés pour chaque item du panier
interface CartItemProps {
  item: {
    id: string;
    Titre: string;
    cover: string;
    quantite: number;
    Prix: number;
    Taille: string;
    Couleur: string;
    priceId: string;
  };
}

const CartItem: React.FC<CartItemProps> = ({ item }) => {
  const dispatch = useDispatch();

  // Fetch du prix mis à jour
  useEffect(() => {
    const fetchUpdatedPrice = async () => {
      try {
        console.log(`Récupération du prix pour le produit: ${item.Titre}`);

        // Étape 1 : Obtenir l'ID de prix par défaut du produit
        const responseProduit = await fetch(
          `https://pulsar-inc.fr:3080/api/stripe/products/${item.id}`
        );
        if (!responseProduit.ok) {
          throw new Error(`Erreur lors de la récupération du produit`);
        }

        const produitData = await responseProduit.json();

        // Étape 2 : Si l'ID de prix par défaut existe, récupérer le montant unitaire
        if (produitData.default_price) {
          const responsePrix = await fetch(
            `https://pulsar-inc.fr:3080/api/stripe/prices/${produitData.default_price}`
          );

          if (!responsePrix.ok) {
            throw new Error(
              `Erreur lors de la récupération du prix par défaut`
            );
          }

          const priceData = await responsePrix.json();
          const updatedPrice = priceData.unit_amount / 100; // Conversion centimes -> euros

          console.log(`Prix récupéré pour ${item.Titre}: ${updatedPrice} €`);

          // Mise à jour du prix dans le Redux store
          dispatch(
            shoppingCartActions.updatePrice({ id: item.id, Prix: updatedPrice })
          );
        }
      } catch (error) {
        console.error(
          `Erreur lors de la récupération du prix pour ${item.Titre}:`,
          error
        );
      }
    };

    // Appel de la fonction si le prix n'est pas encore défini
    if (!item.Prix) {
      fetchUpdatedPrice();
    }
  }, [dispatch, item.id, item.Prix]);

  // Gestion des actions sur les items du panier
  const handleRemoveItem = () => {
    dispatch(shoppingCartActions.removeItemFromCart(item.id));
  };

  const handleIncrementButtonClick = () => {
    dispatch(shoppingCartActions.incrementQuantity(item.id));
  };

  const handleDecrementButtonClick = () => {
    dispatch(shoppingCartActions.decrementQuantity(item.id));
  };

  return (
    <div className="cart-item">
      <div className="left-part">
        <div className="cart-item-image">
          <img src={`${item.cover}`} alt={item.Titre} />
        </div>
        <div className="item-details">
          <div className="Titre">
            <h6>
              {item.Titre} <p>{item.Taille}</p> <p>{item.Couleur}</p>
            </h6>
          </div>
          <p>
            {item.quantite} x {item.Prix} €
          </p>
          <button className="RemoveItem" onClick={handleRemoveItem}>
            Retirer du panier
          </button>
        </div>
      </div>
      <div className="right-part">
        <div className="Selector">
          <span onClick={handleDecrementButtonClick}>-</span>
          <span>{item.quantite}</span>
          <span onClick={handleIncrementButtonClick}>+</span>
        </div>
        <div className="Price">{(item.Prix * item.quantite).toFixed(2)} €</div>
      </div>
    </div>
  );
};

export default CartItem;
