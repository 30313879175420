import React from "react";
import { Evenement } from "../../screens/Evenements";
import MyImage from "../reactLazyLoading";

interface EvenementItemProps {
  evenement: Evenement;
  isPast?: boolean;
  onOpenModal?: (evenement: Evenement) => void;
}

const EvenementItem: React.FC<EvenementItemProps> = ({
  evenement,
  isPast,
  onOpenModal,
}) => {
  const handleOpenModal = () => {
    if (isPast && onOpenModal) {
      onOpenModal(evenement);
    }
  };

  // Ajouter un log pour inspecter les données
  console.log(evenement);

  return (
    <div
      className={`EvenementItem ${isPast ? "clickable" : ""}`}
      onClick={handleOpenModal}
    >
      <MyImage
        src={evenement.photos[0]} // La première image de l'événement
        height="100%"
        width="100%"
        className="EvenementItem-photo"
      />

      <div className="EvenementContent">
        <h2>{evenement.NomEven}</h2>
        <p>{new Date(evenement.dateProp).toLocaleDateString()}</p>
        <p>{new Date(evenement.dateProp).toLocaleTimeString()}</p>
        <p>
          <a
            href={evenement.googleMapsLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            {/* Si Lieu est une chaîne, l'afficher directement, sinon accéder à name */}
            {typeof evenement.Lieu === "string"
              ? evenement.Lieu
              : evenement.Lieu?.name || "Lieu non disponible"}
          </a>
        </p>

        {/* Vérification de l'objet adresse */}
        {typeof evenement.Lieu === "object" && evenement.Lieu?.address && (
          <p>
            Adresse: {evenement.Lieu.address.streetAddress},{" "}
            {evenement.Lieu.address.addressLocality},{" "}
            {evenement.Lieu.address.postalCode},{" "}
            {evenement.Lieu.address.addressCountry}
          </p>
        )}

        <p>Artiste: {evenement.Artiste}</p>
      </div>
      {isPast && (
        <div
          className="three-dots"
          onClick={(e) => {
            e.stopPropagation();
            handleOpenModal();
          }}
        >
          <span className="dot1">&#x2022;</span>
          <span className="dot2">&#x2022;</span>
          <span className="dot3">&#x2022;</span>
        </div>
      )}
    </div>
  );
};

export default EvenementItem;
