import { Routes, Route, useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";

// PAGES
import Evenements from "./screens/Evenements";
import { Boutique } from "./screens/Boutique";
import Contact from "./screens/Contact";
import ProduitDetail from "./screens/Boutique/ProduitDetailsPages";
import CGV from "./screens/CGV";
import Home from "./screens/Home";
import Mitchy from "./screens/Mitchy";
import MusicPlayer from "./screens/MusicPlayer";

const AnimRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes key={location.pathname} location={location}>
        {/* Route pour la page d'accueil */}
        <Route path="/" element={<Home />} />
        {/* Routes pour les autres pages */}
        <Route path="/Mitchy" element={<Mitchy />} />
        <Route
          path="/Mitchy/:albumTitle/:trackTitle"
          element={<MusicPlayer />}
        />
        {/* Route dynamique */}
        <Route path="/Evenements" element={<Evenements />} />
        <Route path="/Boutique" element={<Boutique />} />
        <Route path="/Boutique/:productId" element={<ProduitDetail />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/cgv" element={<CGV />} />
      </Routes>
    </AnimatePresence>
  );
};

export default AnimRoutes;
