import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

interface MyImageProps {
  images?: string[]; // Pour les cas où on a plusieurs images
  src?: string; // Pour les cas où on a une seule image
  height: string;
  width: string;
  className?: string;
}

const MyImage: React.FC<MyImageProps> = ({
  images,
  src,
  height,
  width,
  className,
}) => {
  const baseUrl = "https://pulsar-inc.fr";

  if (images) {
    // Cas où nous avons plusieurs images
    return (
      <div className="ImagesContainer">
        {images.map((image, index) => (
          <LazyLoadImage
            key={index}
            src={`${baseUrl}${image}`}
            alt={`Image ${index + 1}`}
            effect="blur"
            placeholderSrc={`${baseUrl}${image}`}
            height={height}
            width={width}
            className={className}
          />
        ))}
      </div>
    );
  }

  if (src) {
    // Cas où nous avons une seule image
    return (
      <LazyLoadImage
        src={`${baseUrl}${src}`}
        alt="Event Image"
        effect="blur"
        placeholderSrc={`${baseUrl}${src}`}
        height={height}
        width={width}
        className={className}
      />
    );
  }

  return null;
};

export default MyImage;
