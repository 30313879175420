import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaSpotify, FaApple, FaDeezer } from "react-icons/fa";
import MyImage from "../reactLazyLoading";

type Track = {
  number: number;
  title: string;
};

type Album = {
  id: string;
  Titre: string;
  Année: number;
  Pochette: string;
  Tracks: Track[];
  spotifyLink?: string;
  appleMusicLink?: string;
  deezerLink?: string;
};

type AlbumsSectionProps = {
  albumsData: Album[];
};

const AlbumsSection: React.FC<AlbumsSectionProps> = ({ albumsData }) => {
  const [selectedAlbumId, setSelectedAlbumId] = useState<string | null>(null);

  const handleSelectAlbum = (albumId: string) => {
    setSelectedAlbumId(albumId);
    document.body.style.overflow = "hidden";
  };

  const closePopup = () => {
    setSelectedAlbumId(null);
    document.body.style.overflow = "auto";
  };

  const selectedAlbum = albumsData.find(
    (album) => album.id === selectedAlbumId
  );

  return (
    <div className="album-discography">
      {albumsData.map((album) => (
        <motion.div
          key={album.id}
          layoutId={album.id} // Utilisation de layoutId pour l'animation
          onClick={() => handleSelectAlbum(album.id)}
          className="album"
          whileHover={{ scale: 1.05 }}
        >
          <MyImage
            src={album.Pochette}
            height="100%"
            width="100%"
            className=""
          />
          <p className="Atriste-p">{album.Titre}</p>
          <p className="Atriste-p" id="Annee">
            {album.Année}
          </p>
        </motion.div>
      ))}

      <AnimatePresence>
        {selectedAlbum && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={closePopup}
          >
            <motion.div
              layoutId={selectedAlbum.id} // Utilisation de layoutId pour synchroniser la transition
              className="modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <button className="Fermer-modal" onClick={closePopup}>
                <span></span>
              </button>

              <MyImage
                src={selectedAlbum.Pochette}
                height="100%"
                width="100%"
                className=""
              />
              <div className="Atriste-p-container">
                <p className="Atriste-p">{selectedAlbum.Titre}</p>
                <p className="Atriste-p" id="Annee">
                  {selectedAlbum.Année}
                </p>
              </div>

              {/* Lien d'écoute pour les plateformes */}
              <div className="Modal-plateform">
                {selectedAlbum.spotifyLink && (
                  <a
                    href={selectedAlbum.spotifyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Spotify"
                  >
                    <FaSpotify style={{ height: "2em", width: "2em" }} />
                  </a>
                )}
                {selectedAlbum.appleMusicLink && (
                  <a
                    href={selectedAlbum.appleMusicLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Apple Music"
                  >
                    <FaApple style={{ height: "2em", width: "2em" }} />
                  </a>
                )}
                {selectedAlbum.deezerLink && (
                  <a
                    href={selectedAlbum.deezerLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Deezer"
                  >
                    <FaDeezer style={{ height: "2em", width: "2em" }} />
                  </a>
                )}
              </div>

              {/* Tracks de l'album sélectionné */}
              <div className="Modal-titre">
                {selectedAlbum.Tracks.map((track) => (
                  <div className="track-list" key={track.number}>
                    <p id="Annee">{track.number} -</p>
                    <p>{track.title}</p>
                  </div>
                ))}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default AlbumsSection;
