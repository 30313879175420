import LocomotiveScroll from "locomotive-scroll";

export function initializeScroll() {
  const scrollContainer = document.querySelector(
    "[data-scroll-container]"
  ) as HTMLElement;
  let scroll: LocomotiveScroll | null = null;

  if (scrollContainer) {
    scroll = new LocomotiveScroll({
      el: scrollContainer,
      smooth: true,
    });

    // Écouteur pour afficher la position du défilement dans la console
    scroll.on("scroll", (args) => {
      console.log("Locomotive Scroll Position:", args.scroll.y); // args.scroll.y contient la position actuelle du scroll
    });
  }

  // Retourne une fonction de nettoyage qui appelle `scroll.destroy()`
  return () => {
    if (scroll) {
      scroll.destroy();
    }
  };
}
