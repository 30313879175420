import React from "react";
import Slider from "react-slick";
import EvenementItem from "./EvenementItem";
import { Evenement } from "../../screens/Evenements";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface EventSliderProps {
  events: Evenement[];
}

const EventSlider: React.FC<EventSliderProps> = ({ events }) => {
  const currentDate = new Date();
  const oneMonthLater = new Date();
  oneMonthLater.setMonth(currentDate.getMonth() + 1);

  // Filtrer les événements pour le mois en cours
  const filteredEvents = events.filter(
    (event) =>
      new Date(event.dateProp) >= currentDate &&
      new Date(event.dateProp) <= oneMonthLater
  );

  const eventsToShow =
    filteredEvents.length > 0 ? filteredEvents.slice(0, 3) : events.slice(0, 3);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <Slider {...settings}>
      {eventsToShow.map((event) => (
        <div key={event.id}>
          <EvenementItem evenement={event} />
        </div>
      ))}
    </Slider>
  );
};

export default EventSlider;

const NextArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <div className="custom-arrow custom-next" onClick={onClick}>
      <svg viewBox="0 0 24 24">
        <path d="M8 4l8 8-8 8V4z" />
      </svg>
    </div>
  );
};

const PrevArrow: React.FC<{ onClick?: () => void }> = ({ onClick }) => {
  return (
    <div className="custom-arrow custom-prev" onClick={onClick}>
      <svg viewBox="0 0 24 24">
        <path d="M16 4l-8 8 8 8V4z" />
      </svg>
    </div>
  );
};

export { NextArrow, PrevArrow };
