import { useRef, useEffect } from "react";
import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useVelocity,
  useAnimationFrame,
} from "framer-motion";
import { wrap } from "@motionone/utils";

// Interface définissant les props du composant ParallaxText
interface ParallaxProps {
  children: string; // Texte à afficher en défilement
  baseVelocity: number; // Vitesse de base du défilement
}

// Composant ParallaxText pour créer un effet de texte en défilement
const ParallaxText: React.FC<ParallaxProps> = ({
  children,
  baseVelocity = 100,
}) => {
  // Valeur de base pour la position horizontale, initialisée à 0
  const baseX = useMotionValue(0);

  // Récupération de la position de défilement vertical
  const { scrollY } = useScroll();

  // Ajout d'un log pour observer les changements de scrollY
  useEffect(() => {
    const unsubscribe = scrollY.on("change", (value) => {
      console.log("Position de défilement vertical (scrollY):", value);
    });
    return () => unsubscribe();
  }, [scrollY]);

  // Calcul de la vitesse du défilement vertical
  const scrollVelocity = useVelocity(scrollY);

  // Application d'un ressort pour lisser la vitesse de défilement
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 500, // Force de ralentissement pour un effet plus naturel
    stiffness: 400, // Élasticité du ressort
  });

  // Transformation de la vitesse pour influencer le défilement horizontal
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false,
  });

  // Calcul de la position 'x' pour faire défiler le texte avec un effet wrap-around
  const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);

  // Direction du défilement (1 pour l'avant, -1 pour l'arrière)
  const directionFactor = useRef<number>(1);

  // Animation de défilement appelée à chaque frame pour mettre à jour la position
  useAnimationFrame((_, delta) => {
    // Calcul de déplacement basé sur la vitesse et le temps écoulé
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000);

    // Mise à jour de la direction en fonction du facteur de vitesse
    if (velocityFactor.get() < 0) {
      directionFactor.current = -1;
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1;
    }

    // Ajustement du mouvement par le facteur de vitesse
    moveBy += directionFactor.current * moveBy * velocityFactor.get();

    // Mise à jour de la position de base (baseX) avec le nouveau déplacement calculé
    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="parallax-container">
      <motion.div className="scroller" style={{ x }}>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
        <span>{children} </span>
      </motion.div>
    </div>
  );
};

export default ParallaxText;
