export type Track = {
  number: number;
  title: string;
};

export type Détails = {
  Question: string;
  Réponse: string;
};
export type LienPaiement = {
  couleur: string;
  lienDirect: string;
  priceId: string;
  productId: string;
  quantite: number;
};

export type produits = {
  id: string;
  Titre: string;
  type: string;
  enStock: boolean;

  ImageProduit: string;
  ImageProduitSup: string;
  AltText: string;
  to: string;

  // Prix peut rester en option si on le charge dynamiquement
  Prix: number | undefined;
  DescriptionPrincipale: string;
  DescriptionSecondaire?: string;

  LesPlusProduits: string;
  Compositions: string;
  ConseilsUtilisation: string;

  ImagesSupplementaires: string[];
  ImagesUtilisateurs?: string[];
  TexteBouton: string;

  // Gestion des options de paiement, taille et couleur
  LienPaiementUnique?: LienPaiement[];
  LienPaiementTailleS?: LienPaiement[];
  LienPaiementTailleM?: LienPaiement[];
  LienPaiementTailleL?: LienPaiement[];
  LienPaiementTailleXL?: LienPaiement[];
  LienPaiementCouleur0?: LienPaiement[];
  LienPaiementCouleur1?: LienPaiement[];

  besoinChoixCouleur: boolean;
  besoinChoixTaille: boolean;
  OptionsCouleur?: string[];

  Tracks?: Track[];
  Détail?: Détails[];
};

export const produitsData: produits[] = [
  //Vêtements
  {
    id: "prod_P8LNyiroFXZkeC",
    Titre: "Tshirt Pulsar Col Rond",
    type: "vetement",
    AltText: "T-shirt Pulsar Col Rond CLASSIC",
    to: "TshirtPulsarColRond",
    enStock: true,
    ImageProduit:
      "/images/Produits/Fiche/TshirtColRond/Transparent-TshirtAv800.png",
    ImageProduitSup:
      "/images/Produits/Fiche/TshirtColRond/Transparent-TshirtAr800.png",
    ImagesSupplementaires: [
      "/images/Produits/Fiche/TshirtColRond/Transparent-TshirtAv800Détails.png",
      "/images/Produits/Fiche/TshirtColRond/Transparent-TshirtAr800Détails.png",
    ],
    Détail: [
      { Question: "Forme du col", Réponse: "Col Rond" },
      { Question: "Motif / Couleur", Réponse: "Noir" },
      { Question: "Imprimé", Réponse: "Blanc" },
      { Question: "Taille & Coupe", Réponse: "Classique" },
      { Question: "Silhouette", Réponse: "Droit" },
      { Question: "Longueur", Réponse: "Normale" },
      { Question: "Longueur des manches", Réponse: "Manches courtes" },
      {
        Question: "Conseils d'entretien",
        Réponse: "Lavage en machine à 30°C, lavage textiles délicats",
      },
    ],

    Prix: undefined, // À récupérer dynamiquement depuis Stripe
    DescriptionPrincipale:
      "Acheter dès maintenant. Livraison FRANCE METROPOLITAINE UNIQUEMENT. Frais de livraison non inclus.",

    Compositions:
      "Coton peigné avec lavage aux enzymes. Finition double aiguille bas de manche et bas de vêtement. Certifié STANDARD 100 by OEKO-TEX®, IFTH. Grammage 180 g/m2; Coloris unis : 100% coton.",

    ConseilsUtilisation: "",
    LesPlusProduits: "",
    TexteBouton: "Acheter",
    besoinChoixCouleur: false,
    besoinChoixTaille: true,

    LienPaiementTailleS: [
      {
        lienDirect: "https://book.stripe.com/00g3dcbnr2nO5AAeUW",
        priceId: "price_1OK4gcCik0FZhKhASWzdUH5o",
        productId: "prod_P8LNyiroFXZkeC",
        quantite: 1,
        couleur: "",
      },
    ],
    LienPaiementTailleM: [
      {
        lienDirect: "https://book.stripe.com/6oE1540IN6E41kk5kn",
        priceId: "price_1OKzeSCik0FZhKhAdqH7H3mW",
        productId: "prod_P9IEJHaVKRrH2n",
        quantite: 1,
        couleur: "",
      },
    ],
    LienPaiementTailleL: [
      {
        lienDirect: "https://book.stripe.com/9AQ9BA77b5A01kk004",
        priceId: "price_1OKzfXCik0FZhKhAWsZElK5Z",
        productId: "prod_P9IGprd8TorIOz",
        quantite: 1,
        couleur: "",
      },
    ],
    LienPaiementTailleXL: [
      {
        lienDirect: "https://book.stripe.com/28oeVU0IN5A0e767sx",
        priceId: "price_1OKznHCik0FZhKhA6gsMTqwN",
        productId: "prod_P9IOi7sIxk1vxC",
        quantite: 1,
        couleur: "",
      },
    ],
  },
  // T-shirt Pulsar femme
  {
    id: "prod_P8LPuTN6iXzvfy",
    Titre: "Tshirt Pulsar COL V",
    type: "vetement",
    AltText: "T-Shirt Pulsar Col en V CLASSIC",
    to: "TshirtPulsarColV",
    enStock: true,
    ImageProduit:
      "/images/Produits/Fiche/TshirtColV/Transparent-TshirtAv800.png",
    ImageProduitSup:
      "/images/Produits/Fiche/TshirtColV/Transparent-TshirtAr800.png",
    ImagesSupplementaires: [
      "/images/Produits/Fiche/TshirtColV/Transparent-TshirtAv800Détails.png",
      "/images/Produits/Fiche/TshirtColV/Transparent-TshirtAr800Détails.png",
    ],

    Détail: [
      { Question: "Forme du col", Réponse: "Col V" },
      { Question: "Motif / Couleur", Réponse: "Noir" },
      { Question: "Imprimé", Réponse: "Blanc" },
      { Question: "Taille & Coupe", Réponse: "Classique" },
      { Question: "Silhouette", Réponse: "Cintré" },
      { Question: "Longueur", Réponse: "Normale" },
      { Question: "Longueur des manches", Réponse: "Manches courtes" },
      {
        Question: "Conseils d'entretien",
        Réponse: "Lavage en machine à 30°C, lavage textiles délicats",
      },
    ],

    Prix: undefined, // À récupérer dynamiquement depuis Stripe
    DescriptionPrincipale:
      "Précommandez dès maintenant. Livraison FRANCE METROPOLITAINE UNIQUEMENT. Frais de livraison non inclus.",

    Compositions:
      "Coton peigné avec lavage aux enzymes. Bande de propreté à l'encolure. Finition double aiguille bas de manche et bas de vêtement. Certifié STANDARD 100 by OEKO-TEXN CQ1007, IFTH.",

    ConseilsUtilisation: "",
    LesPlusProduits: "",
    ImagesUtilisateurs: ["", "", ""],
    TexteBouton: "Acheter",

    besoinChoixCouleur: false,
    besoinChoixTaille: true,

    LienPaiementTailleS: [
      {
        lienDirect: "https://book.stripe.com/9AQ2981MR7I8gfe5kr",
        priceId: "price_1OK4ikCik0FZhKhAFqp2MyfD",
        productId: "prod_P8LPuTN6iXzvfy",
        quantite: 1,
        couleur: "",
      },
    ],
    LienPaiementTailleM: [
      {
        lienDirect: "https://book.stripe.com/fZe154fDHe6wfba28g",
        priceId: "price_1OKzVGCik0FZhKhAwzrR5bwy",
        productId: "prod_P9I5a9KriUkUXW",
        quantite: 1,
        couleur: "",
      },
    ],
    LienPaiementTailleL: [
      {
        lienDirect: "https://book.stripe.com/4gw7ts1MR8Mc9QQeV4",
        priceId: "price_1OKzW2Cik0FZhKhAoqAQEMGL",
        productId: "prod_P9I6fClA5evZ7y",
        quantite: 1,
        couleur: "",
      },
    ],
    LienPaiementTailleXL: [
      {
        lienDirect: "https://book.stripe.com/fZecNMcrvfaAgfebIR",
        priceId: "price_1OKzWgCik0FZhKhAdKF5uOAI",
        productId: "prod_P9I6EbckofYDH1",
        quantite: 1,
        couleur: "",
      },
    ],
  },

  // Sweat à capuche
  {
    id: "prod_P8LKH5eqTCqZ6o",
    Titre: "SWEAT à capuche brodé",
    type: "vetement",
    AltText: "SWEAT à capuche Pulsar CLASSIC ",
    to: "SweatPulsar",
    enStock: true,
    ImageProduit: "/images/Produits/Fiche/Sweat/Transpparent-SWEATav.png",
    ImageProduitSup: "/images/Produits/Fiche/Sweat/Transparent-SWEATar.png",
    ImagesSupplementaires: [
      "/images/Produits/Fiche/Sweat/Transpparent-SWEATavDétails.png",
      "/images/Produits/Fiche/Sweat/Transparent-SWEATarDétails.png",
      "/images/Produits/Fiche/Sweat/Transpparent-SWEATcotDDétails.png",
      "/images/Produits/Fiche/Sweat/Transpparent-SWEATcotGDétails.png",
    ],

    Détail: [
      { Question: "Col", Réponse: "Capuche" },
      { Question: "Poche", Réponse: "Kangourou" },
      { Question: "Capuche", Réponse: "Capuche avec cordon de serrage" },
      { Question: "Imprimé", Réponse: "Blanc" },
      { Question: "Taille & Coupe", Réponse: "Large" },
      { Question: "Silhouette", Réponse: "Droit" },
      { Question: "Longueur", Réponse: "Normale" },
      { Question: "Longueur des manches", Réponse: "Manches longues" },
      {
        Question: "Informations additionnelles",
        Réponse: "Taille élastique, Broderie",
      },
    ],

    Prix: undefined, // À récupérer dynamiquement depuis Stripe
    DescriptionPrincipale:
      "Précommandez dès maintenant. Livraison FRANCE METROPOLITAINE UNIQUEMENT. Frais de livraison non inclus.",
    Compositions:
      "80% coton / 20% polyester. Traitement LSF (Low Shrinkage Fleece). Molleton gratté 3 fils en coton peigné.",
    LesPlusProduits: "",
    besoinChoixCouleur: false,
    besoinChoixTaille: true,
    TexteBouton: "Acheter",

    LienPaiementTailleS: [
      {
        lienDirect: "https://book.stripe.com/28o6pocrv3rS6EE6oz",
        priceId: "price_1OL01zCik0FZhKhAl4DQrdyi",
        productId: "prod_P8LKH5eqTCqZ6o",
        quantite: 1,
        couleur: "",
      },
    ],
    ConseilsUtilisation: "",
  },

  // Casquette brodée
  {
    id: "prod_P8LReMBUHpDZDi",
    Titre: "Casquette brodée",
    type: "vetement",
    AltText: "Casquette Pulsar CLASSIC",
    to: "CasquettePulsar",
    enStock: true,
    ImageProduit:
      "/images/Produits/Fiche/Casquette/Transparent-Casquette800.png",
    ImageProduitSup:
      "/images/Produits/Fiche/Casquette/Transparent-Casquette800noir.png",
    ImagesSupplementaires: [
      "/images/Produits/Fiche/Casquette/Transparent-Casquette800Détails.png",
      "/images/Produits/Fiche/Casquette/Transparent-Casquette800noirDétails.png",
    ],
    Prix: undefined, // sélectionner dynamiquement depuis Stripe
    DescriptionPrincipale:
      "Précommandez dès maintenant. Livraison FRANCE METROPOLITAINE UNIQUEMENT. Frais de livraison non inclus.",
    Compositions:
      "Coton sergé brossé épais haute qualité. Tissage solide et résistant. Certifié STANDARD 100 by OEKO-TEX.",
    besoinChoixCouleur: true,
    besoinChoixTaille: false,
    TexteBouton: "Acheter",
    OptionsCouleur: ["Noir", "Blanc"],
    LienPaiementCouleur0: [
      {
        lienDirect: "https://book.stripe.com/14kcNM9fjfaA8MMbIX",
        priceId: "price_1OK4kVCik0FZhKhA3ZJxTQQT",
        productId: "prod_P8LReMBUHpDZDi",
        quantite: 1,
        couleur: "Blanc",
      },
    ],
    LesPlusProduits: "",
    ConseilsUtilisation: "",
  },

  //Albums

  //Réflexions
  {
    id: "prod_P9IlTz8Is0awIq",
    type: "album",
    Titre: "Reflexions",
    to: "AlbumRéflexions",
    enStock: true,
    ImageProduit: "/images/Produits/Fiche/Albums/Réflexions.png",
    ImageProduitSup: "/images/Produits/Fiche/Albums/RéflexionsAr.png",
    ImagesSupplementaires: [
      "/images/Produits/Fiche/Albums/Présentation Réflexions (1).mp4",
      "/images/Produits/Fiche/Albums/Réflexions.png",
      "/images/Produits/Fiche/Albums/RéflexionsAr.png",
    ],
    AltText: "",
    Prix: undefined, // Prix à récupérer dynamiquement depuis Stripe
    DescriptionPrincipale:
      "Découvrez 'REFLEXIONS', l'innovant album du rappeur Mitchy. Sorti en 2022, cet opus de 54 minutes se démarque par son concept unique : une clé USB dans un miroir encadré. Avec 15 titres captivants, 'REFLEXIONS' fusionne art et technologie, offrant une expérience musicale riche qui reflète la créativité de Mitchy.",
    DescriptionSecondaire:
      "Livraison FRANCE METROPOLITAINE UNIQUEMENT. Frais de livraison non inclus.",
    Compositions: "",
    ConseilsUtilisation: "",
    LesPlusProduits: "",
    TexteBouton: "Acheter",
    LienPaiementUnique: [
      {
        lienDirect: "https://buy.stripe.com/28o3dcbnr6E4e76aEK",
        priceId: "price_1OL09aCik0FZhKhAZVvkROPZ",
        productId: "prod_P9IlTz8Is0awIq",
        quantite: 1,
        couleur: "",
      },
    ],
    Tracks: [
      { number: 1, title: "Intro" },
      { number: 2, title: "Même si" },
      { number: 3, title: "Impatient" },
      { number: 4, title: "Missile" },
      { number: 5, title: "Ok ok" },
      { number: 6, title: "Mômes (feat Osen)" },
      { number: 7, title: "Immensité" },
      { number: 8, title: "Horizon (feat. Ophéwilk)" },
      { number: 9, title: "Wouaï" },
      { number: 10, title: "Salut terre" },
      { number: 11, title: "Coup de choeur" },
      { number: 12, title: "Dépassé" },
      { number: 13, title: "Mayday" },
      { number: 14, title: "Jump" },
      { number: 15, title: "Réflexion" },
    ],
    besoinChoixCouleur: false,
    besoinChoixTaille: false,
  },
  //Genesis
  {
    id: "prod_P9ItFEQeNf8xWC",
    type: "album",
    Titre: "Genesis",
    to: "AlbumGenesis",
    enStock: true,
    ImageProduit: "/images/Produits/Fiche/Albums/Genesis.png",
    ImageProduitSup: "/images/Produits/Fiche/Albums/GenesisAr.png",
    ImagesSupplementaires: [
      "/images/Produits/Fiche/Albums/GenesisAvAr.png",
      "/images/Produits/Fiche/Albums/Genesis.png",
      "/images/Produits/Fiche/Albums/GenesisAr.png",
    ],
    AltText: "Album GENESIS Mitchy",
    Prix: undefined, // Prix à récupérer dynamiquement depuis Stripe
    DescriptionPrincipale:
      "'GENESIS' de Mitchy, un concentré d'énergie musicale en 23 minutes. Sorti en 2019, cet album offre une expérience intense, marquant un tournant audacieux dans le parcours de Mitchy. Un incontournable pour les amateurs de rythmes captivants.",
    DescriptionSecondaire:
      "Livraison FRANCE METROPOLITAINE UNIQUEMENT. Frais de livraison non inclus.",
    Compositions: "",
    ConseilsUtilisation: "",
    LesPlusProduits: "",
    TexteBouton: "Acheter",
    LienPaiementUnique: [
      {
        lienDirect: "https://buy.stripe.com/28o5lkbnrfaA5AA289",
        priceId: "price_1OL0HbCik0FZhKhAnNLKR73b",
        productId: "prod_P9ItFEQeNf8xWC",
        quantite: 1,
        couleur: "",
      },
    ],
    Tracks: [
      { number: 1, title: "Mini Jim'" },
      { number: 2, title: "Ineffable" },
      { number: 3, title: "Bande à part (feat Shae)" },
      { number: 4, title: "Grande" },
      { number: 5, title: "Solo" },
      { number: 6, title: "Shake it up" },
      { number: 7, title: "Sayonara" },
    ],
    besoinChoixCouleur: false,
    besoinChoixTaille: false,
  },
];
