import React from "react";
import {
  FaInstagram,
  FaTiktok,
  FaYoutube,
  FaSpotify,
  FaApple,
  FaDeezer,
} from "react-icons/fa";

const SocialLinks: React.FC = () => (
  <nav className="ArtisteReseaux">
    <a
      href="https://www.instagram.com/mitchy.rap"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Instagram"
    >
      <FaInstagram />
      <span className="Reseaux">Instagram</span>
    </a>
    <a
      href="https://www.tiktok.com/@mitchy.rap"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="TikTok"
    >
      <FaTiktok />
      <span className="Reseaux">TikTok</span>
    </a>
    <a
      href="https://www.youtube.com/channel/UCHRawJL9vg6X5aBU4mDlqHg"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Youtube"
    >
      <FaYoutube />
      <span className="Reseaux">Youtube</span>
    </a>
    <a
      href="https://open.spotify.com/intl-fr/artist/7l3z99XPjQ36c0pe6DXpQe"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Spotify"
    >
      <FaSpotify />
      <span className="Reseaux">Spotify</span>
    </a>
    <a
      href="https://music.apple.com/us/artist/mitchy/1479084468"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Apple Music"
    >
      <FaApple />
      <span className="Reseaux">Apple Music</span>
    </a>
    <a
      href="https://www.deezer.com/fr/artist/14964329"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="Deezer"
    >
      <FaDeezer />
      <span className="Reseaux">Deezer</span>
    </a>
  </nav>
);

export default SocialLinks;
