import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";
import { initializeScroll } from "../locomotive/scrollInitializer";
import Charnière from "../components/Charnière";
import ParallaxText from "../components/ParallaxText";
import MyImage from "../components/reactLazyLoading";

interface AccueilItem {
  id: number;
  titre: string;
  date: string;
  type: string;
  contenu: string;
  imageUrl: string;
  imageAlt: string;
  link: string;
  ariaLabel: string;
  circleText: string;
}

interface VideoItem {
  id: number;
  scrollSpeed: number;
  videoSrc: string;
  link: string;
  size: string;
  position: string;
  ariaLabel: string;
}

const Home: React.FC = () => {
  const [accueilData, setAccueilData] = useState<AccueilItem[]>([]);
  const [accueilVideos, setAccueilVideos] = useState<VideoItem[]>([]);

  useEffect(() => {
    //Récupère les données d'accueil
    const fetchAccueilData = async () => {
      try {
        const response = await fetch("https://pulsar-inc.fr:3080/api/accueil");
        if (!response.ok) {
          throw new Error(
            "Erreur lors de la récupération des données d'accueil"
          );
        }
        const data: AccueilItem[] = await response.json();
        setAccueilData(data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données d'accueil :",
          error
        );
        setAccueilData([]);
      }
    };
    //Récupère les données des videos d'accueil
    const fetchAccueilVideos = async () => {
      try {
        const response = await fetch(
          "https://pulsar-inc.fr:3080/api/accueil-videos"
        );
        if (!response.ok) {
          throw new Error(
            "Erreur lors de la récupération des données des vidéos d'accueil"
          );
        }
        const data: VideoItem[] = await response.json();
        setAccueilVideos(data);
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des données des vidéos d'accueil :",
          error
        );
        setAccueilVideos([]);
      }
    };

    fetchAccueilData();
    fetchAccueilVideos();
  }, []);

  const preloadImages = (srcArray: string[]) => {
    srcArray.forEach((src) => {
      const img = new Image();
      img.src = src;
    });
  };

  useEffect(() => {
    preloadImages(accueilData.map((item) => item.imageUrl));
  }, [accueilData]);

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const [cursorVariant, setCursorVariant] = useState<"default" | "img">(
    "default"
  );
  //Fonction pour mettre à jour la position de la souris
  useEffect(() => {
    const updateMousePosition = (e: MouseEvent) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };
    window.addEventListener("mousemove", updateMousePosition);
    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
    };
  }, []);

  const variants = {
    default: {
      height: 0,
      width: 0,
      x: mousePosition.x - 75,
      y: mousePosition.y - 75,
    },
    img: {
      height: 100,
      width: 100,
      x: mousePosition.x - 50,
      y: mousePosition.y - 50,
    },
  };

  const imgEnter = () => setCursorVariant("img");
  const imgLeave = () => setCursorVariant("default");

  useEffect(() => {
    const cleanupScroll = initializeScroll();
    return () => {
      if (cleanupScroll) cleanupScroll();
    };
  }, [accueilData, accueilVideos]);

  return (
    <motion.main>
      <Helmet>
        <title>Pulsar Inc. - Accueil</title>
        <meta
          name="description"
          content="Découvrez Pulsar Inc., une association dynamique de Saint-Étienne spécialisée dans la musique. Explorez notre contenu et nos vidéos."
        />
        <meta
          name="keywords"
          content="Pulsar Inc, association musicale, musique Saint-Étienne, vidéos musicales, événements musique, création musicale"
        />
        <meta charSet="UTF-8" />
        <meta httpEquiv="Content-Language" content="fr" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="Pulsar Inc. - Accueil" />
        <meta
          property="og:description"
          content="Explorez l'univers musical de Pulsar Inc. avec des vidéos, des événements et bien plus encore."
        />
        <meta property="og:url" content="https://pulsar-inc.fr" />
        <meta property="og:type" content="website" />
        <meta
          property="og:image"
          content="https://pulsar-inc.fr/images/pulsar-logo.png"
        />

        {/* Twitter Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Pulsar Inc. - Accueil" />
        <meta
          name="twitter:description"
          content="Découvrez Pulsar Inc., une association de musique à Saint-Étienne, avec des vidéos et des événements passionnants."
        />
        <meta
          name="twitter:image"
          content="https://pulsar-inc.fr/images/pulsar-logo.png"
        />

        {/* JSON-LD pour les actualités */}
        {accueilData.map((item) => (
          <script type="application/ld+json" key={item.id}>
            {`
      {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        "headline": "${item.titre}",
        "datePublished": "${item.date}",
        "url": "${item.link}",
        "image": "${item.imageUrl}",
        "articleBody": "${item.contenu}"
      }
      `}
          </script>
        ))}
      </Helmet>

      <motion.div
        className="curseur"
        variants={variants}
        animate={cursorVariant}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "50%",
        }}
      >
        {cursorVariant === "img" && (
          <p className="curseur-text" style={{ margin: 0, fontSize: "10px" }}>
            découvrir
          </p>
        )}
      </motion.div>

      <section className="AccueilContainer">
        <div
          onMouseEnter={imgEnter}
          onMouseLeave={imgLeave}
          className="BanderauGauche"
        >
          <Charnière TexteOutline="PUL" Texte="SAR" />
        </div>

        <section data-scroll-container className="wrap">
          {/* Container vidéo accueil */}

          <section data-scroll-container data-scroll data-scroll-speed="0">
            <div className="ContainerBandeaux">
              {accueilVideos.map((video: VideoItem) => (
                <motion.div
                  key={video.id}
                  className={`${video.size} ${video.position}`}
                  data-scroll
                  data-scroll-speed={video.scrollSpeed}
                >
                  <a
                    href={video.link}
                    onMouseEnter={imgEnter}
                    onMouseLeave={imgLeave}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={video.ariaLabel}
                  >
                    <motion.video
                      initial={{
                        y: video.position === "gauche" ? 250 : -250,
                        opacity: 0,
                      }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{
                        y: video.position === "gauche" ? -250 : 250,
                        opacity: 0,
                      }}
                      transition={{ type: "spring", stiffness: 50 }}
                      src={video.videoSrc}
                      autoPlay
                      muted
                      loop
                      playsInline
                      controlsList="nofullscreen"
                    ></motion.video>
                  </a>
                </motion.div>
              ))}
            </div>
          </section>
          <ParallaxText baseVelocity={1} children="Pulsar inc Actualités" />
          {/* Sections dynamiques pour inner-home */}
          <section
            className="inner-home-container"
            data-scroll-container
            data-scroll
            data-scroll-speed="0"
          >
            {accueilData.map((item: AccueilItem) => (
              <div
                key={item.id}
                className={`inner-home ${
                  item.type === "reverse" ? "reverse" : ""
                }`}
              >
                <div
                  data-scroll
                  data-scroll-speed="1"
                  className={
                    item.type === "reverse"
                      ? "inner-home-info-reverse"
                      : "inner-home-info"
                  }
                >
                  <motion.p
                    className="NewsTitle"
                    initial={{ opacity: 0, y: 10 }} // Animation d'entrée en fondu et légère translation vers le bas
                    animate={{ opacity: 1, y: 0 }} // Position stable
                    exit={{ opacity: 0, y: -10 }} // Animation de sortie en fondu et translation vers le haut
                    transition={{ duration: 0.4 }} // Durée de l'animation
                  >
                    NEWS / {item.date}
                  </motion.p>

                  <motion.p
                    className="content-text"
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -10 }}
                    transition={{ duration: 0.4 }}
                  >
                    {item.contenu}
                  </motion.p>
                </div>

                <motion.div
                  data-scroll
                  data-scroll-speed="5"
                  data-scroll-offset="25%, 15%"
                  className={
                    item.type === "reverse"
                      ? "inner-home-image-reverse"
                      : "inner-home-image"
                  }
                  initial={{ opacity: 1 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0 }}
                >
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label={item.ariaLabel}
                  >
                    <div
                      className="HomeItem"
                      onMouseEnter={imgEnter}
                      onMouseLeave={imgLeave}
                    >
                      <MyImage
                        src={item.imageUrl}
                        height="100%"
                        width="100%"
                        className="HomeItem-photo"
                      />
                    </div>
                  </a>
                </motion.div>
              </div>
            ))}{" "}
            <NavLink to="/Evenements">
              <motion.div
                initial={{ y: 100, opacity: 1 }}
                whileInView={{ y: 0, opacity: 1 }}
                exit={{ y: 250, opacity: 0 }}
                transition={{ type: "spring", stiffness: 50 }}
                viewport={{ once: true }}
                onMouseEnter={imgEnter}
                onMouseLeave={imgLeave}
                className="block-event-inner"
              >
                <span className="outline">NOS</span>{" "}
                <button className="Dimention">
                  {" "}
                  <span>événements</span>{" "}
                </button>
              </motion.div>
            </NavLink>
          </section>
        </section>
      </section>
    </motion.main>
  );
};

export default Home;
