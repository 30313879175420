import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import LocomotiveScroll from "locomotive-scroll";
import ProduitsItems from "../components/Boutique/ProduitsItems";
import CartItem from "./Boutique/Composants/CartIcon";
import Cart from "./Boutique/Composants/Cart";
import { produitsData } from "../data/produitsData";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "./Boutique/Store/store";
import Charnière from "../components/Charnière";

export const Boutique = () => {
  const albums = produitsData.filter((produit) => produit.type === "album");
  const vetements = produitsData.filter(
    (produit) => produit.type === "vetement"
  );

  useEffect(() => {
    const scrollContainer = document.querySelector(
      "[data-scroll-container]"
    ) as HTMLElement;

    if (scrollContainer) {
      const scroll = new LocomotiveScroll({
        el: scrollContainer,
        smooth: true,
      });
      return () => {
        scroll.destroy();
      };
    }
  }, []);

  const canIViewCar = useSelector(
    (state: RootState) => state.shoppingCart.isCartVisible
  );

  return (
    <motion.main>
      <Helmet>
        <title>Pulsar Inc. - Boutique</title>
        <link rel="canonical" href="https://pulsar-inc.fr/Boutique" />
        <meta
          name="description"
          content="Explorez la boutique de Pulsar Inc. et découvrez des vêtements et albums unique pour soutenir nos artistes."
        />
      </Helmet>

      <section className="AccueilContainer">
        <CartItem />
        {canIViewCar && <Cart />}

        <Charnière TexteOutline={"Bout"} Texte={"ique"} />
        <section data-scroll data-scroll-container className="wrap">
          <div className="inner-home-boutique">
            <div className="inner-home-info">
              <div className="GrosTitre-boutique">
                <h1>Collection</h1>
                <span className="outline">Clas</span>sic
              </div>
            </div>
            <div className="inner-home-image-boutique">
              <video
                src="videos/Boutique/129936 (720p).mp4"
                loop
                muted
                autoPlay
                playsInline
              ></video>
            </div>
          </div>

          <div className="NosProduits">
            <ProduitsItems TexteBouton="Acheter" produits={vetements} />
          </div>

          <div className="inner-home-boutique">
            <div className="inner-home-info">
              <div className="GrosTitre-boutique">
                <h1>Albums</h1>
                <span className="outline">MITC</span>HY
              </div>
            </div>
            <div className="inner-home-image-boutique">
              <video
                src="videos/PetitSon/Stop Motion cut.mp4"
                autoPlay
                muted
                loop
                playsInline
              ></video>
            </div>
          </div>

          <div className="NosProduits">
            <ProduitsItems TexteBouton="Acheter" produits={albums} />
          </div>

          <div className="inner-home-boutique reverse">
            <div className="GrosTitre-boutique-reverse">
              GAM<span className="outline">MA</span>
              <h1>FUTURE COLLECTION</h1>
            </div>
            <div className="inner-home-reverse-image-boutique">
              <video
                src="videos/Boutique/131499 (720p).mp4"
                loop
                muted
                autoPlay
                playsInline
              ></video>
            </div>
          </div>
        </section>
      </section>
    </motion.main>
  );
};
