import React, { useState, useEffect } from "react";

// Importation des fichiers CSS
import "./App.css";
import "./locomotive-scroll.css";
import "../src/styles/Components/Charnière.css";
import "../src/styles/Components/ModalCart.css";
import "../src/styles/Components/BtnBox.css";
import "../src/styles/Components/VSlide4.css";
import "../src/styles/Components/VSlide5.css";
import "../src/styles/Components/Header.css";
import "../src/styles/Components/Footer.css";
import "../src/styles/Components/MenuBurger.css";
import "../src/styles/Components/EventDetailsPages.css";
import "../src/styles/Components/CarrouselAuto.css";
import "../src/styles/Components/ScrollToTopButton.css";
import "../src/styles/Components/WelcomeModal.css";

import "../src/styles/Pages/Accueil.css";
import "../src/styles/Pages/Evenements.css";
import "../src/styles/Pages/Artistes.css";
import "../src/styles/Pages/MusicPlayer.css";
import "../src/styles/Pages/Boutique.css";
import "../src/styles/Pages/ProduitDetails.css";
import "../src/styles/Pages/Produits.css";
import "../src/styles/Pages/CGV.css";

// Importation des composants nécessaires
import WelcomeModal from "./components/WelcomeModal";
import Footer from "./components/Footer";
import Headers from "./components/Headers";
import AnimRoutes from "./AnimRoutes";
import ScrollToTopButton from "./components/ScrollToTopButton"; // Import du bouton ScrollToTop

export default function App() {
  // State pour contrôler l'affichage de la modale de bienvenue
  const [showModal, setShowModal] = useState(false);

  // Utilisation de useEffect pour afficher la modale lors de la première visite
  useEffect(() => {
    if (!localStorage.getItem("firstVisit")) {
      setShowModal(true);
      localStorage.setItem("firstVisit", "1"); // Marque la première visite dans le localStorage
    }
  }, []);

  const closeModal = () => setShowModal(false);

  return (
    <>
      <WelcomeModal showModal={showModal} closeModal={closeModal} />
      <Headers />
      <AnimRoutes />
      <ScrollToTopButton />
      <Footer />
    </>
  );
}
