import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { motion } from "framer-motion";
import EvenementItem from "../components/Evenements/EvenementItem";
import EvenementModal from "../components/Evenements/EvenementModal";
import EventSlider from "../components/Evenements/EventSlider";
import Charnière from "../components/Charnière";
import axios from "axios";

export type Evenement = {
  id: number;
  NomEven: string;
  Artiste: string[];
  Lieu: {
    name: string;
    address?: {
      streetAddress?: string;
      addressLocality?: string;
      postalCode?: number;
      addressCountry?: string;
    };
  };
  dateProp: Date;
  googleMapsLink: string;
  photos: string[];
};

const Evenements: React.FC = () => {
  const [evenementsFuturs, setEvenementsFuturs] = useState<Evenement[]>([]);
  const [evenementsPasses, setEvenementsPasses] = useState<Evenement[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedEvenement, setSelectedEvenement] = useState<Evenement | null>(
    null
  );

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await axios.get(
          "https://pulsar-inc.fr:3001/api/events/"
        );
        const events = response.data;

        if (!Array.isArray(events)) {
          throw new Error("La réponse de l'API n'est pas un tableau");
        }

        const currentDate = new Date();
        const futurs = events
          .filter((evenement) => new Date(evenement.dateProp) >= currentDate)
          .sort(
            (a, b) =>
              new Date(a.dateProp).getTime() - new Date(b.dateProp).getTime()
          );
        const passes = events
          .filter((evenement) => new Date(evenement.dateProp) < currentDate)
          .sort(
            (a, b) =>
              new Date(b.dateProp).getTime() - new Date(a.dateProp).getTime()
          );

        setEvenementsFuturs(futurs);
        setEvenementsPasses(passes);
      } catch (error) {
        console.error("Erreur lors de la récupération des événements :", error);
      }
    };

    fetchEvents();
  }, []);

  const handleOpenModal = (evenement: Evenement) => {
    setSelectedEvenement(evenement);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedEvenement(null);
  };

  return (
    <motion.main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Pulsar Inc. - Événements</title>
        <meta
          name="description"
          content="Découvrez tous les événements à venir à Saint-Étienne organisés par Pulsar Inc."
        />
        <meta
          name="keywords"
          content="Pulsar, Événements, Saint-Étienne, Concerts, Artistes"
        />
        <meta name="author" content="Pulsar Inc." />
        <link rel="canonical" href="https://pulsar-inc.fr/Evenements" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />

        {/* Open Graph Meta Tags for Social Sharing */}
        <meta
          property="og:title"
          content="Événements à Saint-Étienne - Pulsar Inc."
        />
        <meta
          property="og:description"
          content="Explorez les événements à Saint-Étienne organisés par Pulsar Inc."
        />
        <meta property="og:url" content="https://pulsar-inc.fr/Evenements" />
        <meta
          property="og:image"
          content="https://pulsar-inc.fr/images/evenement-thumbnail.jpg"
        />
        <meta property="og:type" content="website" />
        <meta property="og:locale" content="fr_FR" />

        {/* Twitter Card Meta Tags for Social Sharing */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Événements à Saint-Étienne - Pulsar Inc."
        />
        <meta
          name="twitter:description"
          content="Explorez les événements à Saint-Étienne organisés par Pulsar Inc."
        />
        <meta
          name="twitter:image"
          content="https://pulsar-inc.fr/images/evenement-thumbnail.jpg"
        />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@graph": evenementsPasses.map((event) => ({
              "@type": "Event",
              name: event.NomEven,
              startDate: new Date(event.dateProp).toISOString(),
              location: {
                "@type": "Place",
                name: event.Lieu.name,
                address: {
                  "@type": "PostalAddress",
                  streetAddress: event.Lieu.address?.streetAddress || "",
                  addressLocality: event.Lieu.address?.addressLocality || "",
                  postalCode: event.Lieu.address?.postalCode || "",
                  addressCountry: event.Lieu.address?.addressCountry || "",
                },
              },
              image:
                event.photos.length > 0
                  ? `https://pulsar-inc.fr${event.photos[0]}`
                  : "",
              description: `Découvrez l'événement "${event.NomEven}" organisé par Pulsar Inc.`,

              // Vérification si Artiste est un tableau ou une chaîne
              performer: Array.isArray(event.Artiste)
                ? event.Artiste.map((artist) => ({
                    "@type": "MusicGroup",
                    name: artist,
                  }))
                : [
                    {
                      "@type": "MusicGroup",
                      name: event.Artiste, // Si c'est une chaîne
                    },
                  ],

              offers: {
                "@type": "Offer",
                url: event.googleMapsLink,
                price: "0",
                priceCurrency: "EUR",
                availability: "https://schema.org/InStock",
              },
            })),
          })}
        </script>
      </Helmet>

      <section className="AccueilContainer">
        <Charnière TexteOutline="événe" Texte="ments" />

        <section className="wrap">
          <main className="Evenements">
            <h1> Événements à la une :</h1>
            <section className="Evenement-ALU">
              <EventSlider events={evenementsFuturs.slice(0, 3)} />
            </section>
            <h2> Événements futurs : </h2>
            <section className="Evenement-PC">
              <div className="Container-Cartes-Evenement">
                {evenementsFuturs.map((event) => (
                  <EvenementItem key={event.id} evenement={event} />
                ))}
              </div>
            </section>
            <h2>Evénements passés :</h2>
            <section className="Evenement-PS">
              <div className="Container-Cartes-Evenement">
                {evenementsPasses.map((event) => (
                  <EvenementItem
                    key={event.id}
                    evenement={event}
                    isPast
                    onOpenModal={handleOpenModal}
                  />
                ))}
              </div>
            </section>
          </main>
        </section>
      </section>
      {isModalOpen && selectedEvenement && (
        <EvenementModal
          evenement={selectedEvenement}
          onClose={handleCloseModal}
        />
      )}
    </motion.main>
  );
};

export default Evenements;
