import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

interface ContentItem {
  type: "text" | "link";
  value?: string;
  text?: string;
  url?: string;
}

interface ModalConfig {
  title: string;
  content: ContentItem[];
}

interface WelcomeModalProps {
  showModal: boolean;
  closeModal: () => void;
}

const WelcomeModal: React.FC<WelcomeModalProps> = ({
  showModal,
  closeModal,
}) => {
  const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null);

  // récupère la data
  useEffect(() => {
    fetch("https://pulsar-inc.fr:3080/api/welcome-modal")
      .then((response) => response.json())
      .then((data) => setModalConfig(data))
      .catch((error) =>
        console.error(
          "Erreur lors du chargement de la configuration du modal:",
          error
        )
      );
  }, []);

  const handleBackdropClick = (event: React.MouseEvent) => {
    if (event.currentTarget === event.target) {
      closeModal();
    }
  };

  const renderContent = () => {
    return modalConfig?.content.map((item, index) => {
      if (item.type === "link") {
        return (
          <p key={index}>
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.text}
            </a>
            {item.value && <span> - {item.value}</span>}
          </p>
        );
      }
      if (item.type === "text") {
        return <p key={index}>{item.value}</p>;
      }
      return null;
    });
  };

  if (!showModal || !modalConfig) return null;

  return (
    <AnimatePresence>
      {showModal && (
        <React.Fragment>
          {/* Arrière-plan flouté avec animation */}
          <motion.div
            className="modalBackdrop"
            onClick={handleBackdropClick}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          />

          {/* Popup avec animation de zoom */}
          <motion.div
            className="WelcomeModal"
            onClick={(e) => e.stopPropagation()}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
            transition={{ duration: 0.5, ease: "easeOut" }}
          >
            <h2>{modalConfig.title}</h2>
            {renderContent()}
            <button onClick={closeModal}>Fermer</button>
          </motion.div>
        </React.Fragment>
      )}
    </AnimatePresence>
  );
};

export default WelcomeModal;
