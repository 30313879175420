import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { motion, useScroll } from "framer-motion";
import Charnière from "../components/Charnière";

// Type pour structurer les données de l'album et des paroles avec les timestamps
type Release = {
  id: string;
  Titre: string;
  Année: number;
  Pochette: string;
  spotifyLink: string;
  appleMusicLink: string;
  deezerLink: string;
  Tracks: {
    number: number;
    title: string;
    lyrics: { timestamp: number; text: string }[];
  }[];
};
const data = {
  releases: [
    {
      id: "A1",
      Titre: "Réflexions",
      Année: 2022,
      Pochette: "/images/NosArtistes/Mitchy/Discographie/PochetteReflexion.png",
      spotifyLink:
        "https://open.spotify.com/intl-fr/album/3w0Bn3mMhRv4hzbtQhDj7B",
      appleMusicLink: "https://music.apple.com/fr/album/reflexions/1608552903",
      deezerLink: "https://www.deezer.com/fr/album/292945072",
      Tracks: [
        {
          number: 1,
          title: "Intro",
          lyrics: [{ timestamp: 0, text: "Hello" }],
        },
        {
          number: 2,
          title: "Même si",
          lyrics: [{ timestamp: 0, text: "Hello" }],
        },
        {
          number: 3,
          title: "Impatient",
          lyrics: [{ timestamp: 0, text: "Hello" }],
        },
        {
          number: 4,
          title: "Missile",
          lyrics: [
            { timestamp: 0, text: "..." },
            { timestamp: 12, text: "Bonjour, bonsoir, je reviens pour la win" },
            { timestamp: 14, text: "Le son qui passe et les bitchs s’animent" },
            { timestamp: 16, text: "Poète camé, j’ai pas besoin de lean," },
            {
              timestamp: 17,
              text: "Mais la plume est avide",
            },
            {
              timestamp: 18,
              text: "Donc j’ai besoin de lignes",
            },
            {
              timestamp: 19,
              text: "J’vais remettre tous les compteurs à zéro, fréro",
            },
            { timestamp: 20, text: "Si t’as du biff, nous le ramasserons" },
            { timestamp: 22, text: "Taffes la diction et revois ta scéno'" },
            {
              timestamp: 23,
              text: "J’vais pas louper mon but comme Zidane en péno'",
            },
            { timestamp: 25, text: "Joues pas l’fou, t’es pas crédible" },
            { timestamp: 26, text: "Fais pas l’bonhomme de té-ci" },
            {
              timestamp: 28,
              text: "Ton album tu l’ré-édites",
            },
            {
              timestamp: 29,
              text: "Moi j’sors que des inédits !",
            },
            {
              timestamp: 30,
              text: "Ils ont tous courber l’échine pour quelques dineros",
            },
            {
              timestamp: 33,
              text: "Peu importe tout tes chiffres, le « un » mon numéro",
            },

            // Pre-Refrain
            {
              timestamp: 37,
              text: "Toi t’hésites, mais mon équipe ne fait qu’avancer",
            },
            {
              timestamp: 39,
              text: "J’t’ai déjà dis, pas d’égotrip, je fais que commencer",
            },
            { timestamp: 43, text: "Pas d'commentaire" },
            { timestamp: 44, text: "J’sais comment faire !" },
            { timestamp: 45, text: "Pour pas qu’on m'enterre" },
            { timestamp: 46, text: "J’sais comment faire !" },
            // Refrain
            { timestamp: 48, text: "Y a dix piges, vous vous marri - marriez" },
            { timestamp: 52, text: "Sur l’équipe, fallait pari - parier" },
            { timestamp: 55, text: "T’as compris, j’ai l’art et la manière" },
            { timestamp: 58, text: "Des missiles Dans le bari - barillet" },
            { timestamp: 61, text: "Y a dix piges, vous vous marri - marriez" },
            { timestamp: 64, text: "Sur l’équipe, fallait pari - parier" },
            { timestamp: 67, text: "T’as compris, j’ai l’art et la manière" },
            { timestamp: 70, text: "Des missiles Dans le bari - barillet" },

            // Couplet 2
            {
              timestamp: 73,
              text: "Y a beaucoup de tchoins dans des clips qui m’attristent",
            },
            {
              timestamp: 75,
              text: "Si tu fermes ta gueule, tu s’ras p’têtre « The artist »",
            },
            { timestamp: 79, text: "Remballes tes flows, tes gimmicks" },
            { timestamp: 80, text: "Sors du jeu, je t’élimine" },
            { timestamp: 82, text: "Tu m’connais pas, t’es mimi" },
            { timestamp: 83, text: "T’étais où pendant l'zénith ? Hein ?" },
            { timestamp: 86, text: "Perds ta confiance, redescends," },
            { timestamp: 87, text: "Fallait parier y a dix ans" },
            { timestamp: 88, text: "Mates le flow c’est indécent" },
            { timestamp: 89, text: "Écoutes :" },
            { timestamp: 90, text: "Recules de là si tu n’es pas de taille" },
            { timestamp: 91, text: "J’en ai marre que l’on compare nos bails" },
            {
              timestamp: 92,
              text: "J’t’écoute pas mais j’observe tes failles",
            },
            {
              timestamp: 93,
              text: "Quand j’vois ton Spotify, ça n’me dis rien qui vaille",
            },

            // Pre-Refrain
            {
              timestamp: 96,
              text: "Toi t’hésites, mais mon équipe ne fait qu’avancer",
            },
            {
              timestamp: 99,
              text: "J’t’ai déjà dis, pas d’égotrip, je fais que commencer",
            },
            { timestamp: 103, text: "Pas d'commentaire" },
            { timestamp: 104, text: "J’sais comment faire !" },
            { timestamp: 105, text: "Pour pas qu’on m'enterre" },
            { timestamp: 106, text: "J’sais comment faire !" },
            // Refrain
            {
              timestamp: 108,
              text: "Y a dix piges, vous vous marri - marriez",
            },
            { timestamp: 112, text: "Sur l’équipe, fallait pari - parier" },
            { timestamp: 115, text: "T’as compris, j’ai l’art et la manière" },
            { timestamp: 118, text: "Des missiles Dans le bari - barillet" },
            {
              timestamp: 121,
              text: "Y a dix piges, vous vous marri - marriez",
            },
            { timestamp: 124, text: "Sur l’équipe, fallait pari - parier" },
            { timestamp: 127, text: "T’as compris, j’ai l’art et la manière" },
            { timestamp: 130, text: "Des missiles Dans le bari - barillet" },

            // Pont
            {
              timestamp: 133,
              text: "Tu m’invites sur les réseaux mais y a comme bug !",
            },
            { timestamp: 136, text: "Si tu parles mal on te fumera comme bud" },
            {
              timestamp: 139,
              text: "On va foutre le zbeul, fais tourner le skeud",
            },
            { timestamp: 142, text: "Si tu parles mal on te fumera comme bud" },

            // Pre-Refrain
            {
              timestamp: 145,
              text: "Toi t’hésites, mais mon équipe ne fait qu’avancer",
            },
            {
              timestamp: 147,
              text: "J’t’ai déjà dis, pas d’égotrip, je fais que commencer",
            },
            { timestamp: 152, text: "Pas d'commentaire" },
            { timestamp: 153, text: "J’sais comment faire !" },
            { timestamp: 154, text: "Pour pas qu’on m'enterre" },
            { timestamp: 155, text: "J’sais comment faire !" },
            // Refrain final
            {
              timestamp: 157,
              text: "Y a dix piges, vous vous marri - marriez",
            },
            { timestamp: 161, text: "Sur l’équipe, fallait pari - parier" },
            { timestamp: 164, text: "T’as compris, j’ai l’art et la manière" },
            { timestamp: 167, text: "Des missiles Dans le bari - barillet" },
            {
              timestamp: 170,
              text: "Y a dix piges, vous vous marri - marriez",
            },
            { timestamp: 173, text: "Sur l’équipe, fallait pari - parier" },
            { timestamp: 176, text: "T’as compris, j’ai l’art et la manière" },
            { timestamp: 179, text: "Des missiles Dans le bari - barillet" },
          ],
        },
        {
          number: 5,
          title: "Ok ok",
          lyrics: [{ timestamp: 0, text: "Hello" }],
        },
      ],
    },
  ],
};
const MusicPlayer: React.FC = () => {
  const { albumTitle, trackTitle } = useParams<{
    albumTitle: string;
    trackTitle: string;
  }>();
  const releaseFound = data.releases.find(
    (release) => release.Titre === albumTitle
  );
  const track = releaseFound?.Tracks.find((t) => t.title === trackTitle);
  const audioRef = useRef<HTMLAudioElement>(null);
  const [currentLyricIndex, setCurrentLyricIndex] = useState<number>(0);

  // Synchronisation des paroles avec l'audio
  useEffect(() => {
    const audio = audioRef.current;
    if (!audio || !track || !track.lyrics) return;

    const updateProgress = () => {
      const currentTime = audio.currentTime;
      const currentIndex = track.lyrics.findIndex(
        (lyric, index) =>
          currentTime >= lyric.timestamp &&
          (!track.lyrics[index + 1] ||
            currentTime < track.lyrics[index + 1].timestamp)
      );

      if (currentIndex !== -1 && currentIndex !== currentLyricIndex) {
        setCurrentLyricIndex(currentIndex);
      }

      requestAnimationFrame(updateProgress);
    };

    updateProgress();
  }, [track, currentLyricIndex]);

  if (!releaseFound || !track) return <p>Album ou piste introuvable</p>;

  const audioSrc = `/videos/PetitSon/${track.title}.mp3`;

  return (
    <motion.main>
      <section className="AccueilContainer">
        <Charnière TexteOutline={"Art"} Texte={"iste"} />
        <section className="wrap">
          <div className="music-player">
            {track.lyrics && (
              <div className="lyrics-container">
                <div className="audio-container">
                  <audio ref={audioRef} controls autoPlay preload="auto" loop>
                    <source src={audioSrc} type="audio/mp3" />
                    Votre navigateur ne supporte pas l'élément audio.
                  </audio>
                </div>
                <div className="lyrics">
                  {track.lyrics.map((lyric, index) => (
                    <div
                      key={index}
                      className={`lyric-line ${
                        index === currentLyricIndex ? "highlighted" : ""
                      }`}
                    >
                      {lyric.text}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </section>
      </section>
    </motion.main>
  );
};

export default MusicPlayer;
