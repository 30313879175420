import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { transition1 } from "../../transition";

type Produit = {
  id: string; // Utilisez l'ID Stripe ici
  Titre: string;
  type: string;
  enStock: boolean;
  Prix: number | undefined;
  ImageProduit: string;
  ImageProduitSup: string;
  AltText: string;
  to: string;
};

type ProduitsItemsProps = {
  produits: Produit[];
  TexteBouton: string;
};

const ProduitsItems: React.FC<ProduitsItemsProps> = ({
  produits,
  TexteBouton,
}) => {
  const [produitsAvecPrix, setProduitsAvecPrix] = useState<Produit[]>(produits);

  useEffect(() => {
    const fetchPrixManquants = async () => {
      const produitsAMettreAJour = produitsAvecPrix.map(async (produit) => {
        if (produit.Prix === undefined) {
          const urlProduct = `https://pulsar-inc.fr:3080/api/stripe/products/${produit.id}`;
          console.log(
            `Fetching default price ID for ${produit.Titre} at URL: ${urlProduct}`
          ); // Log de vérification

          try {
            const responseProduct = await fetch(urlProduct);
            if (!responseProduct.ok) {
              throw new Error(
                `Erreur HTTP ${responseProduct.status} pour ${urlProduct}`
              );
            }
            const dataProduct = await responseProduct.json();
            const defaultPriceId = dataProduct.default_price;

            if (defaultPriceId) {
              const urlPrice = `https://pulsar-inc.fr:3080/api/stripe/prices/${defaultPriceId}`;
              console.log(
                `Fetching price for ${produit.Titre} at URL: ${urlPrice}`
              );

              const responsePrice = await fetch(urlPrice);
              if (!responsePrice.ok) {
                throw new Error(
                  `Erreur HTTP ${responsePrice.status} pour ${urlPrice}`
                );
              }
              const dataPrice = await responsePrice.json();

              return {
                ...produit,
                Prix: dataPrice.unit_amount / 100,
              };
            } else {
              console.warn(`Aucun default_price trouvé pour ${produit.Titre}`);
              return produit;
            }
          } catch (error) {
            console.error(
              `Erreur de récupération du prix pour ${produit.Titre} à l'URL ${urlProduct}`,
              error
            );
            return produit;
          }
        }
        return produit;
      });

      const updatedProduits = await Promise.all(produitsAMettreAJour);
      setProduitsAvecPrix(updatedProduits);
    };

    fetchPrixManquants();
  }, [produits]);

  const handleItemClick = (titre: string, to: string) => {
    console.log(`Item clicked - Titre: ${titre}, Redirecting to: ${to}`);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <>
      {produitsAvecPrix.map((produit) => (
        <motion.div
          key={produit.id}
          initial={{ opacity: 0, scale: 0 }}
          transition={transition1}
          whileInView={{ opacity: 1, scale: 1, transition: { duration: 0.4 } }}
          viewport={{ once: true }}
          className="Carte-Produits-Items"
        >
          <Link
            className="Carte-Produits-Items-haut"
            to={produit.to}
            onClick={() => handleItemClick(produit.Titre, produit.to)}
          >
            <div className="pulse boutique">
              <span style={{ "--i": 0 } as React.CSSProperties}></span>
              <span style={{ "--i": 1 } as React.CSSProperties}></span>
              <span style={{ "--i": 2 } as React.CSSProperties}></span>
            </div>
            <img
              className="Carte-Produits-Items-haut-img"
              src={produit.ImageProduit}
              alt={produit.AltText}
              onLoad={() =>
                console.log(`Image loaded - ${produit.ImageProduit}`)
              }
              onError={() =>
                console.error(
                  `Erreur de chargement pour l'image : ${produit.ImageProduit}`
                )
              }
            />
            <img
              className="Carte-Produits-Items-haut-img-supperpose"
              src={produit.ImageProduitSup}
              alt={produit.AltText}
              onLoad={() =>
                console.log(
                  `Image de superposition chargée - ${produit.ImageProduitSup}`
                )
              }
              onError={() =>
                console.error(
                  `Erreur de chargement pour l'image de superposition : ${produit.ImageProduitSup}`
                )
              }
            />
          </Link>
          <div className="Carte-Produits-Items-bas">
            <Link to={produit.to}>
              <h6>{produit.Titre}</h6>
            </Link>
            <p>
              {typeof produit.Prix === "number"
                ? `${produit.Prix.toFixed(2)} €`
                : "Prix non disponible"}
            </p>
            <Link to={produit.to}>
              <button>
                <span className="Panier">{TexteBouton}</span>
              </button>
            </Link>
          </div>
        </motion.div>
      ))}
    </>
  );
};

export default ProduitsItems;
