import React from "react";
import { motion } from "framer-motion";

type Video = {
  id: string;
  src: string;
  className: string;
};

type VideosSectionProps = {
  videosData: Video[];
};

const VideosSection: React.FC<VideosSectionProps> = ({ videosData }) => (
  <div className="ContainerFormesArtistes">
    {videosData.map((video) => (
      <div className="FormeArtistes" key={video.id}>
        <motion.video
          initial={{ y: -250, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 250, opacity: 0 }}
          transition={{ type: "spring", stiffness: 50 }}
          src={video.src}
          className={video.className}
          autoPlay
          muted
          loop
          playsInline
        ></motion.video>
      </div>
    ))}
  </div>
);

export default VideosSection;
