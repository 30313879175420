import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaSpotify, FaApple, FaDeezer } from "react-icons/fa";
import MyImage from "../reactLazyLoading";

type Track = {
  number: number;
  title: string;
};

type EP = {
  id: string;
  Titre: string;
  Année: number;
  Pochette: string;
  Tracks: Track[];
  spotifyLink?: string;
  appleMusicLink?: string;
  deezerLink?: string;
};

type EPsSectionProps = {
  EPsData: EP[];
};

const EPsSection: React.FC<EPsSectionProps> = ({ EPsData }) => {
  const [selectedEPId, setSelectedEPId] = useState<string | null>(null);

  const handleSelectEP = (epId: string) => {
    setSelectedEPId(epId);
    document.body.style.overflow = "hidden"; // Empêche le scroll quand la popup est ouverte
  };

  const closePopup = () => {
    setSelectedEPId(null);
    document.body.style.overflow = "auto"; // Réactive le scroll quand la popup est fermée
  };

  const selectedEP = EPsData.find((ep) => ep.id === selectedEPId);

  return (
    <div className="album-discography">
      {EPsData.map((EP) => (
        <motion.div
          key={EP.id}
          layoutId={EP.id} // Utilisation de layoutId pour l'animation
          onClick={() => handleSelectEP(EP.id)}
          className="album"
          whileHover={{ scale: 1.05 }}
        >
          <MyImage src={EP.Pochette} height="100%" width="100%" className="" />
          <p className="Atriste-p">{EP.Titre}</p>
          <p className="Atriste-p" id="Annee">
            {EP.Année}
          </p>
        </motion.div>
      ))}

      <AnimatePresence>
        {selectedEP && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="modal"
            onClick={closePopup}
          >
            <motion.div
              layoutId={selectedEP.id}
              className="modal-content"
              onClick={(e) => e.stopPropagation()}
            >
              <button className="Fermer-modal" onClick={closePopup}>
                <span></span>
              </button>

              <MyImage
                src={selectedEP.Pochette}
                height="100%"
                width="100%"
                className=""
              />
              <div className="Atriste-p-container">
                <p className="Atriste-p">{selectedEP.Titre}</p>
                <p className="Atriste-p" id="Annee">
                  {selectedEP.Année}
                </p>
              </div>

              {/* Lien d'écoute pour les plateformes */}
              <div className="Modal-plateform">
                {selectedEP.spotifyLink && (
                  <a
                    href={selectedEP.spotifyLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Spotify"
                  >
                    <FaSpotify style={{ height: "2em", width: "2em" }} />
                  </a>
                )}
                {selectedEP.appleMusicLink && (
                  <a
                    href={selectedEP.appleMusicLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Apple Music"
                  >
                    <FaApple style={{ height: "2em", width: "2em" }} />
                  </a>
                )}
                {selectedEP.deezerLink && (
                  <a
                    href={selectedEP.deezerLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Deezer"
                  >
                    <FaDeezer style={{ height: "2em", width: "2em" }} />
                  </a>
                )}
              </div>

              <div className="Modal-titre">
                {selectedEP.Tracks.map((track) => (
                  <div className="track-list" key={track.number}>
                    <p id="Annee">{track.number} -</p>
                    <p>{track.title}</p>
                  </div>
                ))}
              </div>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default EPsSection;
