// src/screens/Mitchy.tsx
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import Charnière from "../components/Charnière";

//importation des données des artistes
import VideosSection from "../components/Artistes/VideosSection";
import AlbumsSection from "../components/Artistes/AlbumsSection";
import EPsSection from "../components/Artistes/EpsSection";
import SinglesSection from "../components/Artistes/SinglesSection";
import SocialLinks from "../components/Artistes/SocialLinks";

//Animation
import { motion } from "framer-motion";
import { initializeScroll } from "../locomotive/scrollInitializer";

type Track = {
  number: number;
  title: string;
};

type Album = {
  id: string;
  Titre: string;
  Année: number;
  Pochette: string;
  Tracks: Track[];
};

type Single = {
  id: string;
  Titre: string;
  Année: number;
  Pochette: string;
  Track: Track;
  spotifyLink?: string;
  appleMusicLink?: string;
  deezerLink?: string;
};

const Mitchy: React.FC = () => {
  const [albumsData, setAlbumsData] = useState<Album[]>([]);
  const [EPsData, setEPsData] = useState<Album[]>([]);
  const [singlesData, setSinglesData] = useState<Single[]>([]);
  const [videosData, setVideosData] = useState<any[]>([]);

  useEffect(() => {
    const fetchAlbumsData = async () => {
      try {
        const response = await fetch("https://pulsar-inc.fr:3080/api/albums");
        const data: Album[] = await response.json();
        setAlbumsData(data);
      } catch (error) {
        console.error("Erreur de récupération des albums :", error);
      }
    };
    const fetchEPsData = async () => {
      try {
        const response = await fetch("https://pulsar-inc.fr:3080/api/eps");
        const data: Album[] = await response.json();
        setEPsData(data);
      } catch (error) {
        console.error("Erreur de récupération des EPs :", error);
      }
    };
    const fetchSinglesData = async () => {
      try {
        const response = await fetch("https://pulsar-inc.fr:3080/api/singles");
        const data: Single[] = await response.json();
        setSinglesData(data);
      } catch (error) {
        console.error("Erreur de récupération des singles :", error);
      }
    };
    const fetchVideosData = async () => {
      try {
        const response = await fetch("https://pulsar-inc.fr:3080/api/videos");
        const data = await response.json();
        setVideosData(data.videos);
      } catch (error) {
        console.error("Erreur de récupération des vidéos :", error);
      }
    };

    fetchAlbumsData();
    fetchEPsData();
    fetchSinglesData();
    fetchVideosData();
  }, []);

  const albumTitles = albumsData.map((album) => album.Titre).join(", ");
  const trackTitles = albumsData
    .flatMap((album) => album.Tracks.map((track) => track.title))
    .join(", ");

  // Initialisation du scroll au chargement du composant
  useEffect(() => {
    const cleanupScroll = initializeScroll();
    return () => {
      if (cleanupScroll) cleanupScroll();
    };
  }, [albumsData, EPsData, singlesData, videosData]);

  return (
    <motion.main>
      <Helmet>
        <title>Pulsar Inc. - Mitchy</title>
        <link rel="canonical" href="https://pulsar-inc.fr/Mitchy" />
        <meta
          name="description"
          content={`Découvrez Mitchy, un artiste rappeur de Saint-Étienne. Explorez ses albums ${albumTitles} et ses tracks ${trackTitles}, disponibles sur toutes les plateformes.`}
        />
        <meta
          name="keywords"
          content={`Mitchy, rappeur Saint-Étienne, albums Mitchy, tracks Mitchy, ${albumTitles}, ${trackTitles}, rap Saint-Étienne, Pulsar Inc, rap français`}
        />
        <meta
          property="og:title"
          content="Mitchy - Artiste Rap Saint-Étienne | Pulsar inc."
        />
        <meta
          property="og:description"
          content={`Découvrez Mitchy, un artiste rappeur de Saint-Étienne avec des albums comme ${albumTitles}.`}
        />
        <meta
          property="og:image"
          content="https://pulsar-inc.fr/images/NosArtistes/Mitchy/Mitchy-blanc.png"
        />
        <meta property="og:url" content="https://pulsar-inc.fr/Mitchy" />
        <meta property="og:type" content="website" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`Mitchy - Albums: ${albumTitles}`}
        />
        <meta
          name="twitter:description"
          content={`Découvrez les albums et tracks de Mitchy: ${trackTitles}.`}
        />
        <meta
          name="twitter:image"
          content="https://pulsar-inc.fr/images/NosArtistes/Mitchy/Mitchy-blanc.png"
        />

        {/* JSON-LD pour les albums et tracks de Mitchy */}
        <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org",
                "@type": "MusicGroup",
                "name": "Mitchy",
                "url": "https://pulsar-inc.fr/Mitchy",
                "album": [
                  ${albumsData
                    .map(
                      (album) => `{
                    "@type": "MusicAlbum",
                    "name": "${album.Titre}",
                    "datePublished": "${album.Année}",
                    "url": "https://pulsar-inc.fr/Mitchy#${album.id}",
                    "track": [
                      ${album.Tracks.map(
                        (track) => `{
                        "@type": "MusicRecording",
                        "name": "${track.title}",
                        "position": "${track.number}"
                      }`
                      ).join(",")}
                    ]
                  }`
                    )
                    .join(",")}
                ]
              }
            `}
        </script>
      </Helmet>
      <section className="AccueilContainer">
        <Charnière TexteOutline={"Art"} Texte={"iste"} />

        <section className="wrap">
          <div className="logo-artiste">
            <img
              src="images/NosArtistes/Mitchy/Biographie/Mitchy.png"
              alt="Logo Mitchy"
              loading="lazy"
            />
          </div>

          <VideosSection videosData={videosData} />
          <SocialLinks />

          {/* Section Albums */}
          <div
            className="block-event-inner-artiste"
            style={{
              backgroundImage:
                "url('/images/NosArtistes/Mitchy/Biographie/MineMitchy.jpg')",
              backgroundPosition: "100% 52%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            data-scroll
            data-scroll-speed="1"
          >
            <div className="GrosTitre-artiste">
              AL<span className="outline-artiste">BUMS</span>
            </div>
          </div>
          <AlbumsSection albumsData={albumsData} />

          {/* Section EPs */}
          <div
            className="block-event-inner-artiste"
            style={{
              backgroundImage:
                "url('/images/NosArtistes/Mitchy/Biographie/Mitchy - Guizay-5.jpg')",
              backgroundPosition: "100% 35%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            data-scroll
            data-scroll-speed="1"
          >
            <div className="GrosTitre-artiste">
              EP<span className="outline-artiste">S</span>
            </div>
          </div>
          <EPsSection EPsData={EPsData} />

          {/* Section Singles */}
          <div
            className="block-event-inner-artiste"
            style={{
              backgroundImage:
                "url('/images/NosArtistes/Mitchy/Biographie/Mitchy fil-35.jpg')",
              backgroundPosition: "100% 52%",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
            data-scroll
            data-scroll-speed="1"
          >
            <div className="GrosTitre-artiste">
              SIN<span className="outline-artiste">GLES</span>
            </div>
          </div>
          <SinglesSection singlesData={singlesData} />
        </section>
      </section>
    </motion.main>
  );
};

export default Mitchy;
