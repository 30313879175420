import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Reorder } from "framer-motion";
import CartItem from "./CratItem";
import { RootState } from "../Store/store";
import { motion } from "framer-motion";
import { shoppingCartActions } from "../Store/shopping-cart-slice";

const stripePromise = loadStripe(
  "pk_live_51O8fUtCik0FZhKhAfypFXdzey87eEBFXA5qUkzimN1fWi9hj3DpyNk444OxOpHUJJohwQMheN6BZ8GKafI89OOWA00fM6CV8YZ"
);

export default function Cart() {
  const dispatch = useDispatch();
  const items = useSelector((state: RootState) => state.shoppingCart.items);
  const [orderedItems, setOrderedItems] = useState(items);

  useEffect(() => {
    setOrderedItems(items);
  }, [items]);

  const handleClickBackdrop = () => {
    dispatch(shoppingCartActions.toggleCartView());
  };

  const handleClickModal = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const prixTotal = useMemo(() => {
    return orderedItems.reduce((total, item) => {
      return total + item.Prix * item.quantite;
    }, 0);
  }, [orderedItems]);

  const payerAvecStripe = async () => {
    const itemsAvecPriceId = orderedItems.filter((item) => item.priceId);

    if (itemsAvecPriceId.length === 0) {
      alert(
        "Votre panier contient des articles sans prix. Veuillez les retirer avant de continuer."
      );
      return;
    }

    try {
      const response = await fetch(
        "https://pulsar-inc.fr:3080/api/stripe/create-checkout-session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            items: itemsAvecPriceId.map((item) => ({
              priceId: item.priceId,
              quantite: item.quantite,
            })),
          }),
        }
      );

      if (!response.ok) {
        throw new Error("La réponse du réseau n'était pas ok");
      }

      const responseData = await response.json();
      const stripe = await stripePromise;

      if (stripe && responseData.sessionId) {
        const result = await stripe.redirectToCheckout({
          sessionId: responseData.sessionId,
        });
        if (result.error) {
          alert(result.error.message);
        }
      } else {
        alert(
          "Erreur lors de la création de la session de paiement. Veuillez réessayer."
        );
      }
    } catch (error) {
      alert(
        "Une erreur est survenue lors de la communication avec le serveur."
      );
    }
  };

  return (
    <div className="BackdropModalCart" onClick={handleClickBackdrop}>
      <motion.div
        className="ModalCart"
        initial={{ x: "100vw", opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        exit={{ x: "100vw", opacity: 0 }}
        transition={{ duration: 0.3 }}
        onClick={handleClickModal}
      >
        <h2>Votre panier comporte ({orderedItems.length}) éléments</h2>
        {orderedItems.length > 0 ? (
          <Reorder.Group
            axis="y"
            onReorder={setOrderedItems}
            values={orderedItems}
          >
            {orderedItems.map((item) => (
              <Reorder.Item key={item.id} value={item}>
                <CartItem item={item} />
              </Reorder.Item>
            ))}
          </Reorder.Group>
        ) : (
          <p>Votre panier est vide</p>
        )}
        <div className="CalculPrixTotal">
          <p>Total : {prixTotal.toFixed(2)} €</p>
        </div>
        <button onClick={payerAvecStripe} className="Panier">
          Valider le panier
        </button>
      </motion.div>
    </div>
  );
}
